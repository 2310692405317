import * as React from 'react';
import styles from './main-header.module.css';
import mixpanel from 'mixpanel-browser';
import clsx from 'clsx';
import { darkTheme } from 'theme';
import { Tooltip, ThemeProvider } from '@SBGSports/referee-react';
import { LinearProgress, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { ReactComponent as NotificationsIcon } from 'assets/icons/24px/notifications.svg';
import { ReactComponent as CatapultIconLogo } from 'assets/catapult-icon-logo.svg';
import { useTranslations } from 'i18n';
import { NotificationsDrawer, useUnreadNotificationsCount } from 'notifications';
import { OAuthDialog } from 'oauth';
import { Helmet } from 'react-helmet-async';
import { HashRouter, Link, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { ReportSelector } from 'reporting/components/report-selector';
import { useSetRedirect } from 'reporting/hooks/use-set-redirect';
import { IconButton, Usetiful, ZenDesk, appTitle, countMax } from 'shared';
import { UpdatesDialog, useUnreadUpdatesCount } from 'updates';
import { AccountMenu } from 'user';
import { SupportButton } from 'support/button/support-button';
import { OfflineBanner } from './offline-banner';
import { useHasFeature } from 'api';
import { FrontendConfigFeaturesEnum, UserPermissionsEnum } from 'oas';
import { usePermission } from 'auth';

interface MainHeaderProps {
    withNav?: boolean;
}

export const MainHeader: React.FC<MainHeaderProps> = ({ withNav = true }) => {
    const isFetching = useIsFetching({
        // Don't show global loading indicator for polling requests (e.g. notifications)
        predicate: (query) =>
            query.state.fetchStatus === 'fetching' && !(query.options as { refetchInterval: boolean }).refetchInterval,
    });
    const isMutating = useIsMutating();
    const unreadUpdateCount = useUnreadUpdatesCount();
    const unreadNotificationCount = useUnreadNotificationsCount();
    const { __ } = useTranslations();
    const history = useHistory();
    const match = useRouteMatch();
    const setRedirect = useSetRedirect();

    const showEditor = useHasFeature(FrontendConfigFeaturesEnum.ShowEditor);
    const showTimeline = useHasFeature(FrontendConfigFeaturesEnum.ShowTimeline);
    const showUpdates = useHasFeature(FrontendConfigFeaturesEnum.ShowUpdates);
    const showLeague = useHasFeature(FrontendConfigFeaturesEnum.ShowLeague);
    const showReporting = useHasFeature(FrontendConfigFeaturesEnum.ShowReporting);
    const hasShowDownloads = useHasFeature(FrontendConfigFeaturesEnum.ShowDownloads);

    // only show settings if timeline or reporting is present
    const showSettings = usePermission(UserPermissionsEnum.Settings);
    const showDownload = usePermission(UserPermissionsEnum.Download) && !showLeague && hasShowDownloads;

    const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] = React.useState(false);
    const [isUpdatesDialogOpen, setIsUpdatesDialogOpen] = React.useState<boolean>(false);

    const toggleNotifications = React.useCallback(() => {
        const isOpen = !isNotificationsDialogOpen;
        setIsNotificationsDialogOpen(isOpen);

        mixpanel.track(isOpen ? 'BellNotificationPanelOpened' : 'BellNotificationPanelClosed');
    }, [isNotificationsDialogOpen]);

    const handleCloseDialog = React.useCallback(() => {
        // history.push does not work by itself because of mixture of BrowserRouter and HashRouter
        window.location.href = '#';

        // replace so we don't need to click back twice to re-open
        history.replace({ hash: '' });
    }, [history]);

    const items = [
        {
            href: '/reporting',
            labelText: __('reporting.reporting'),
            show: showReporting,
            id: 'header-reporting',
        },
        {
            href: '/timeline',
            labelText: __('header.timeline'),
            show: showTimeline,
            id: 'header-timeline',
        },
        {
            href: '/editor',
            labelText: __('header.editor'),
            show: showEditor,
            id: 'header-activity-editor',
        },
        {
            href: '/download',
            labelText: __('header.download'),
            show: showDownload,
            id: 'header-download',
        },
        {
            href: '/settings',
            labelText: __('header.settings'),
            show: showSettings,
            id: 'header-settings',
        },
        {
            href: '#updates',
            labelText: __('misc.updates'),
            labelSuppl: (
                <span className={styles['unread-badge']} id="updates-unread-badge">
                    {countMax(unreadUpdateCount, 9)}
                </span>
            ),
            show: showUpdates,
            classes: { [styles['unread']]: unreadUpdateCount },
            id: 'header-updates',
            onClick: () => setIsUpdatesDialogOpen(true),
        },
        {
            href: '/league',
            labelText: __('league_migration.league'),
            show: showLeague,
            id: 'header-league',
        },
    ];

    return (
        <>
            <Helmet>
                <title>{appTitle()}</title>
            </Helmet>
            {isFetching || isMutating ? (
                <div className={styles['loader']} style={{ zIndex: 99998 }}>
                    <LinearProgress data-testid={`main-progress-bar`} />
                </div>
            ) : null}
            <div style={{ zIndex: 99999, position: 'relative' }}>
                <OfflineBanner />
            </div>
            <MuiThemeProvider theme={darkTheme}>
                <ThemeProvider theme="dark">
                    <div className={styles['layout-header']}>
                        <header className={styles['nav-bar']}>
                            <a
                                href="/"
                                onClick={() => {
                                    setRedirect();
                                }}
                                className={styles['logo-link']}
                            >
                                <CatapultIconLogo className={styles['logo-image']} />
                            </a>
                            <input className={styles['menu-btn']} type="checkbox" id="menu-btn" />
                            <label className={styles['menu-icon']} htmlFor="menu-btn">
                                <span className={styles['nav-icon']}></span>
                            </label>
                            {withNav && (
                                <>
                                    <nav className={styles['menu']}>
                                        {items.map((item) => {
                                            const {
                                                show,
                                                href,
                                                labelText,
                                                labelSuppl,
                                                classes = {},
                                                id,
                                                onClick,
                                            } = item;
                                            const label = (
                                                <>
                                                    <span className={styles['label']}>{labelText}</span>
                                                    {labelSuppl}
                                                </>
                                            );
                                            return (
                                                show && (
                                                    <div
                                                        key={id}
                                                        id={id}
                                                        className={clsx(
                                                            styles['menu-item'],
                                                            href === '/reporting' && styles['menu-item-report-select'],
                                                            {
                                                                [styles['active']]:
                                                                    (match?.path === '/' && href === '/') ||
                                                                    (href !== '/' && match?.url.indexOf(href) === 0),
                                                                ...classes,
                                                            },
                                                        )}
                                                    >
                                                        {href === '/reporting' ? (
                                                            <ReportSelector isActive={match?.url.indexOf(href) === 0} />
                                                        ) : href.indexOf('#') === 0 ? (
                                                            <a href={href} onClick={onClick}>
                                                                {label}
                                                            </a>
                                                        ) : (
                                                            <Link to={href}>{label}</Link>
                                                        )}
                                                    </div>
                                                )
                                            );
                                        })}
                                    </nav>
                                    <div className={styles['info-items']}>
                                        <div
                                            className={clsx(styles['notifications-icon'], {
                                                [styles['unread']]: unreadNotificationCount,
                                            })}
                                        >
                                            <Tooltip text={__('notifications.notifications')}>
                                                <IconButton
                                                    onClick={toggleNotifications}
                                                    id="notifications-icon-button"
                                                >
                                                    <NotificationsIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <span className={styles['unread-badge']} id="notifications-unread-badge">
                                                {countMax(unreadNotificationCount, 9)}
                                            </span>
                                        </div>
                                        <div className={styles['help-icon']}>
                                            <SupportButton />
                                        </div>
                                        <div className={styles['account']}>
                                            <AccountMenu />
                                        </div>
                                    </div>
                                </>
                            )}
                        </header>
                    </div>
                </ThemeProvider>
            </MuiThemeProvider>
            <HashRouter hashType="noslash">
                {showTimeline && (
                    <>
                        <Link to="/updates" onClick={() => setIsUpdatesDialogOpen(true)}></Link>
                        <UpdatesDialog open={isUpdatesDialogOpen} onClose={() => setIsUpdatesDialogOpen(false)} />
                    </>
                )}
                <Route path="/oauth">
                    <OAuthDialog open={true} onClose={handleCloseDialog} />
                </Route>
                <Route path="/notifications"></Route>
            </HashRouter>
            <NotificationsDrawer open={isNotificationsDialogOpen} onClose={toggleNotifications} />
            <Usetiful />
            <ZenDesk />
        </>
    );
};
