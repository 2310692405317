import * as React from 'react';
import { DialogProps } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/16px/cancel.svg';
import { useGetNotices } from 'api/notices';
import mixpanel from 'mixpanel-browser';
import { Dialog, IconButton, usePrevious, useRunOnce } from 'shared';
import { UpdatesList } from './updates-list';
import styles from './updates.module.css';
import { useUnreadUpdatesCount } from './use-unread-updates-count';

interface UpdatesDialogProps extends DialogProps {
    onClose: () => void;
}

export const UpdatesDialog: React.FC<UpdatesDialogProps> = (props) => {
    const { onClose, open } = props;
    // API
    const { data: notices, isFetchedAfterMount } = useGetNotices();
    const unreadUpdateCount = useUnreadUpdatesCount();

    // Local state
    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    useRunOnce(() => {
        const displayDialog = notices?.some((item) => item.notify_on_login === 1 && item.notified === 0);
        setShowDialog(!!displayDialog);
    }, isFetchedAfterMount);

    const handleCloseDialog = () => {
        mixpanel.track('NotificationModal', {
            modal: 'close',
            num_unread: unreadUpdateCount,
            num_updates: notices?.length || 0,
        });
        onClose && onClose();
        setShowDialog(false);
    };

    const wasOpen = usePrevious(open);
    React.useEffect(() => {
        if (!wasOpen && open && notices?.length) {
            mixpanel.track('NotificationModal', {
                modal: 'open',
                num_unread: unreadUpdateCount,
                num_updates: notices?.length || 0,
            });
        }
    }, [wasOpen, open, notices, unreadUpdateCount]);

    return (
        <Dialog {...props} open={open || !!showDialog} onClose={handleCloseDialog} maxWidth={false}>
            <div className={styles['updates-dialog__container']}>
                <UpdatesList />
                <div className={styles['updates-dialog__close-button']}>
                    <IconButton aria-label="close" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
};
